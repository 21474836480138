@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  h1 {
    @apply text-4xl;
    @apply font-bold;
  }
  h2 {
    @apply text-2xl;
    @apply font-bold;
  }
  h3 {
    @apply text-lg;
    @apply font-semibold;
  }
  h4 {
    @apply text-lg;
    @apply font-semibold;
  }
  li {
    @apply text-lg;
    @apply font-semibold;
  }
  p {
    @apply text-lg;
    @apply font-semibold;
  }
  a {
    @apply text-blue-600 underline;
  }
}
@import url('https://fonts.googleapis.com/css?family=Domine|Open+Sans|Rozha+One');

html {
  scroll-behavior: smooth;
}

body {
  height: 100vh;
  width: 100vw;
  background-color: white;
  margin: 0;
  font-family:
    "Open Sans",
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: underline;
  color: #3b82f6;
}

.statistical-report-filter .rs-picker .rs-picker-toggle {
  box-shadow: none !important;
  border: none !important;
  background-color: #e7f2fd !important;
  border-radius: 60px !important;
  justify-content: start !important;
}

.statistical-report-filter .rs-picker-select .rs-picker-toggle {
  padding: 8px 20px !important;
}

.statistical-report-filter .rs-picker-tag .rs-picker-toggle {
  padding: 5px 20px !important;
}

.statistical-report-filter .rs-picker-tag {
  box-shadow: none !important;
  border: none !important;
  background-color: #e7f2fd !important;
  border-radius: 60px !important;
  padding: 3px 10px !important;
  justify-content: start !important;
}

.statistical-report-filter .rs-picker .rs-picker-caret-icon {
  fill: #126fd6 !important;
}

.statistical-report-filter .rs-picker-toggle-value {
  font-size: 16px !important;
  line-height: 24px !important;
  color: #2f363d !important;
}

.statistical-report-filter .rs-picker-select .rs-picker-caret-icon {
  margin-top: 4px;
}

.statistical-report-filter .rs-picker-toggle-label:after {
  content: "" !important;
}

.statistical-report-filter .rs-picker-select .rs-stack-item {
  height: 24px !important;
}

.statistical-report-filter .rs-picker-toggle-placeholder {
  font-size: 16px !important;
  line-height: 24px !important;
}

.popup {
  background-color: #f6f7f8 !important;
}

.popup .rs-picker-select-menu-item {
  color: #2f363d !important;
  font-weight: 400 !important;
}

.popup .rs-picker-select-menu-item-active {
  background-color: #eef0f2 !important;
}

.popup .rs-picker-select-menu-item:hover {
  background-color: #eef0f2 !important;
}

.popup .rs-checkbox-checker > label {
  color: #2f363d !important;
  font-weight: 400 !important;
}

.popup .rs-checkbox-checked {
  background-color: #eef0f2 !important;
}
.popup .rs-check-item-focus {
  background-color: inherit !important;
}

.popup .rs-check-item:not(.rs-checkbox-disabled):hover {
  background-color: #eef0f2 !important;
}

.custom-tag {
  background-color: transparent !important;
  color: #2f363d !important;
  font-size: 16px !important;
  margin-left: 0px !important;
}

.custom-tag:not(:last-child):after {
  content: ",";
}

.custom-tag:not(:first-child) {
  padding-left: 0px !important;
}

.popup .rs-checkbox-inner:before {
  border-width: px !important;
  border-color: #454f5a !important;
}
.popup .rs-checkbox-checked .rs-checkbox-inner:before {
  background-color: #126fd6 !important;
  border-color: #126fd6 !important;
}

.conversation ol {
  list-style: decimal;
  padding-left: 2rem;
  padding-top: 20px;
  padding-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  border-spacing: 50px 0;
  text-align: left;
}
th,
td {
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 24px 12px;
}

@keyframes dropBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes minimize {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes maximize {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.maximize-animation {
  animation: maximize 0.5s ease-in-out forwards;
}

.minimize-animation {
  animation: minimize 0.5s ease-in-out forwards;
}

.line-break {
  white-space: pre-wrap;
}

.markdown-content {
  margin: 0 0; /* Adds 20px space above and below */
}

.markdown-content h1 {
  font-size: 14px;
  font-weight: 600;
  color: #161A1D;
  line-height: 20px;
}

.markdown-content h2 {
  font-size: 14px;
  font-weight: 600;
  color: #161A1D;
  line-height: 20px;
}

.markdown-content h3 {
  font-size: 14px;
  font-weight: 600;
  color: #161A1D;
  line-height: 20px;
}

.markdown-content h4 {
  font-size: 14px;
  font-weight: 400;
  color: #5D6B79;
  line-height: 20px;
}

.markdown-content p {
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 400;
  color: #5D6B79;
  line-height: 20px;
}

.markdown-content strong {
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 400;
  color: #5D6B79;
  line-height: 20px;
}

.markdown-content ul {
  list-style-type: disc;
  margin-left: 20px; /* Indent the list */
}

.markdown-content ol {
  list-style-type: decimal;
  margin-left: 20px;
}

.markdown-content li {
  margin-bottom: 5px; /* Space between list items */
  font-size: 14px;
  font-weight: 400;
  color: #5D6B79;
  line-height: 20px;
}

.markdown-content li br {
  display: none;
}

#root {
  height: 100%;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

.home8-menu .rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  background-color: inherit;
  color: #575757;
}

.home8-menu .rs-picker-select-menu-item.rs-picker-select-menu-item:hover {
  color: #1675e0;
  background-color: rgb(from #cce9ff r g b/50%);
}

.home8-picker.rs-picker-default .rs-btn, .rs-picker-default .rs-picker-toggle, .rs-picker-input .rs-btn, .rs-picker-input .rs-picker-toggle{
  background-color: transparent !important;
}

.home8-picker .rs-picker-toggle .rs-stack .rs-picker-toggle-indicator {
  display: none;
}

.home8-picker .rs-picker-toggle {
  border: none;
  box-shadow: none;
  padding-left: 20px !important;
}

.home8-picker .rs-picker-toggler {
  padding-left: 20px !important;
}

.home8-picker .rs-picker-search-input {
  padding-left: 20px !important;
}

.property-report-menu .tree-node {
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  color: #5D6B79;
}

.property-report-menu .tree-node__branch {
  display: flex;
  align-items: center;
}

.property-report-menu .tree-node--selected {
  background-color: white;
  font-size: 12px;
  font-weight: 700;
  color: #161A1D;
}

.conversation p {
  font-weight: 400;
  font-size: 14px;
}

.language-selector {
  position: relative;
  margin-top: auto;
  width: 100%; /* Ensure it takes the full width minus padding */
  text-align: center;
}

.selected-language {
  color: #B1BAC3;
  padding: 8px 12px 8px 12px;
  border: none;
  border-radius: 60px;
  font-size: 12px;
  line-height: 16.34px;
  font-weight: 400;
  min-width: 95px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selected-language:hover {
  background-color: #2F363D;
}

.selected-language .arrow {
  margin-left: 10px;
}

.dropdown-menu {
  position: absolute;
  bottom: 100%; /* Position above the button */
  left: 0;
  background-color: #2F363D;
  border-radius: 8px;
  margin-bottom: 4px;
  width: 100%;
}

.dropdown-item {
  padding: 8px 12px;
  color: #B1BAC3;
  font-size: 12px;
  line-height: 16.34px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
}

.dropdown-item:hover {
  background-color: #454F5A;
}

.dropdown-item .mark-icon {
  width: 24px; /* Adjust the size of the icon */
  height: 24px;
  margin-right: 10px;
  visibility: hidden;
}

.dropdown-item:hover .mark-icon {
  visibility: visible;
}

.conversation li {
  font-weight: 400;
  font-size: 14px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 5s linear infinite; /* Adjust duration (1s) as needed */
}

input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.conversation h2 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.conversation h3 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.conversation h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.conversation p {
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.conversation strong {
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.conversation ul {
  list-style-type: disc;
  margin-left: 20px; /* Indent the list */
}

.conversation ol {
  list-style-type: decimal;
  margin-left: 20px;
  padding: 0;
}

.conversation li {
  margin-bottom: 5px; /* Space between list items */
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

/* For Webkit browsers (Chrome, Safari) */
.scroll-smooth {
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-smooth::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-smooth {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.scroll-snap-container {
  height: calc(100vh - 72px);
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

.scroll-snap-section {
  scroll-snap-align: start;
  min-height: calc(100vh - 72px);
}
